<template>
    <el-dialog
        width="400px"
        title="分享我的职位名片"
        custom-class="share-job-share-dialog"
        :visible.sync="dialogVisible"
        v-loading="loading"
        @close="handleCloseDialog"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
    >
        <div class="share-info-edit-container">
            <div class="share-info-edit">
                <div class="share-info-top">
                    <span class="share-info-title" v-if="!isEditShareTitle">{{userInfo.linkHeadline}}</span>
                    <el-input type="textarea" :maxlength="50" rows="2" v-model="linkHeadline" v-else></el-input>
                </div>
                <div class="share-info-bottom">
                    <span class="share-info-text">
                        {{userInfo.realName}}：{{userInfo.signature}}
                    </span>
                    <img src="https://hstatic.hirede.com/lbd/images/job-share-logo.jpg">
                </div>
            </div>
            <span class="text-operate-btn share-info-edit-btn" v-if="!isEditShareTitle" @click="isEditShareTitle = true">编辑</span>
            <div class="share-info-edit-tip" v-if="!isEditShareTitle">
                点击编辑即可自定义分享文案！
            </div>
            <span class="text-operate-btn share-info-confirm-btn" v-if="isEditShareTitle" @click="updateJobShareCardInfo">保存</span>
        </div>
        <div class="share-qcode-container">
            <img :src="shareQcodeSrc" class="share-qcode-image"/>
            <div class="share-qcode-tip">
                打开微信，扫一扫～快速分享我的职位名片
                <br/>
                <br/>
                可到【<span class="text-operate-btn" @click="goToMySharedJob">我分享的职位</span>】查看修改我分享的职位，分享中职位：{{userInfo.jobSharingCount}}个
            </div>
        </div>
    </el-dialog>
</template>

<script>
import userService from '#/js/service/userService.js'
export default {
    data() {
        return {
            loading: false,
            dialogVisible: false,
            userInfo: {
                realName: "",
                linkHeadline: "",
                signature: "",
                jobSharingCount: 0
            },
            linkHeadline: "",
            isEditShareTitle: false,
            shareQcodeSrc: ""
        }
    },
    methods: {
        show() {
            this.dialogVisible = true;
            this.getShareJobCardInfo();
        },
        getShareJobCardInfo() {
            this.loading = true;
            let jobShareUrl = `${_host.h5_page}/jobShareCard.html?unionId=${this.$store.state.user.userInfo.unionId}`;
            this.shareQcodeSrc = `${_host.portal}/Sharing/QRCode?url=${encodeURIComponent(jobShareUrl)}`
            userService.getMyJobShareCardLinkInfo()
            .then(res => {
                this.userInfo = Object.assign({}, res);
                this.userInfo.linkHeadline = res.linkHeadline ? res.linkHeadline : '职位更新！hot job！有兴趣请私聊我，也欢迎引荐靠谱朋友';
                this.linkHeadline = this.userInfo.linkHeadline;
            }).catch(err => {
                console.log(err)
                if(err.error && err.error.description) {
                    shortTips(err.error.description)
                } else {
                    shortTips('系统异常，请稍后重试')
                }
            }).finally(() => {
                this.loading = false;
            })
        },
        updateJobShareCardInfo() {
            this.loading = true;
            userService.updateJobShareCardInfo({
                linkHeadline: this.linkHeadline
            })
            .then(res => {
                this.userInfo.linkHeadline = this.linkHeadline;
                this.isEditShareTitle = false;
            }).catch(err => {
                console.log(err)
                if(err.error && err.error.description) {
                    shortTips(err.error.description)
                } else {
                    shortTips('系统异常，请稍后重试')
                }
            }).finally(() => {
                this.loading = false;
            })
        },
        goToMySharedJob() {
            this.handleCloseDialog();
            if(location.href.indexOf('/Headhunting/MyCompany.html') > -1) {
                window.open("/Headhunting/#/myOrders/sharedJob");
            } else {
                location.href = "/Headhunting/#/myOrders/sharedJob?from=myorder";
            }
        },
        handleCloseDialog() {
            this.dialogVisible = false;
            this.isEditShareTitle = false;
            this.linkHeadline = "";
        }
    }
}
</script>

<style lang="scss" scope>
    .share-job-share-dialog.el-dialog {
        .el-dialog__body {
            padding: 15px 0 20px;
        }
        .share-info-edit-container {
            min-height: 152px;
            padding: 0 10px 0 20px;
            position: relative;
            border-bottom: 7px solid #f3f3f3;
            .share-info-edit {
                width: 284px;
                min-height: 128px;
                box-shadow: 0 0 4px 0 rgba(0,0,0,0.50);
                padding: 12px;
                .share-info-top {
                    margin-bottom: 8px;
                    .share-info-title {
                        color: #666666;
                        line-height: 20px;
                        height: 44px;
                        display: inline-block;
                    }
                    .el-textarea__inner {
                        padding: 2px 5px;
                        resize: none;
                        line-height: 19px;
                    }
                }
                .share-info-bottom {
                    display: flex;
                    justify-content: space-between;
                    .share-info-text {
                        width: 194px;
                        font-size: 12px;
                        color: #9B9B9B;
                        line-height: 18px;
                    }
                    img {
                        width: 56px;
                        height: 56px;
                    }
                }
            }
            .share-info-edit-btn {
                position: absolute;
                top: 11px;
                right: 56px;
            }
            .share-info-edit-tip {
                position: absolute;
                right: 12px;
                top: 40px;
                width: 74px;
                color: #999;
                line-height: 20px;
            }
            .share-info-confirm-btn {
                position: absolute;
                right: 56px;
                top: 14px;
            }
        }
        .share-qcode-container {
            height: 126px;
            padding: 12px 36px 0 20px;
            display: flex;
            justify-content: space-between;
            .share-qcode-image {
                width: 114px;
                height: 114px;
            }
            .share-qcode-tip {
                width: 215px;
                margin-top: 8px;
                color: #666;
                line-height: 20px;
            }
        }
    }
</style>
