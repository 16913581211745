<template>
    <el-dialog
        width="700px"
        title="修改职位状态"
        :visible.sync="dialogVisible"
        v-loading="loading"
        @close="handleCloseDialog"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        append-to-body
    > 
        <div class="publish-info" v-if="!isBatch">
            <div class="info-left" :title="`${jobName}[${jobData.location}]-${jobData.firmShortName}`">
                <span class="info-title">职位：</span>
                <span>{{jobName}}</span>
                <span class="info-location">[{{jobData.location}}]</span>
                -
                <span>{{jobData.firmShortName}}</span>
            </div>
            <div class="info-middle">
                <span class="info-title">佣金：</span>
                <span class="info-warning">{{jobData.commissionValue}}</span>
            </div>
            <div class="info-right">
                <span class="info-title">保证期：</span>
                <span class="info-warning">{{jobData.guaranteePeriod}}</span>
                个月
            </div>
        </div>
        <div class="batch-publish-job-info" v-else>
            你正在将
            <span
                class="batch-public-job-name"
                v-for="(job, index) in jobList"
                :key="index"
                v-show="index < 5"
            >
                {{job.jobName}}<span v-if="jobList.length > 1 && ((jobList.length <= 5 && index < jobList.length - 1) || (jobList.length > 5 && index < 4))">、</span>
            </span>
            <span class="batch-public-job-length" v-if="jobList.length > 1">
                {{jobList.length > 5 ? `等 ${jobList.length} 个职位` : `${jobList.length}个职位`}}
            </span>
            发布到市场
        </div>
        <el-form
            ref="publishForm"
            label-width="130px"
            :model="publishForm"
            :rules="publishFormRules"
        >
            <el-form-item label="市场佣金：" prop="publicCommissionValue">
                <el-select
                    class="input-commission-type"
                    popper-class="commission-type-dropdown"
                    v-model="publishForm.publicCommissionType"
                    placeholder="佣金类型"
                    :disabled="isEdit"
                >
                    <el-option label="按比例" :value="0"></el-option>
                    <el-option label="固定佣金" :value="1"></el-option>
                </el-select>
                <el-input
                    class="input-commission-value"
                    v-model.number="publishForm.publicCommissionValue"
                    :disabled="isEdit"
                >
                    <span slot="suffix">{{publishForm.publicCommissionType == 0 ? "%" : "K(千)"}}</span>
                </el-input>
            </el-form-item>
            <el-form-item
                label="分佣比例："
                prop="headhuntingFirmCommissionValue"
                class="firm-commission-value"
                v-if="!isEdit || (isEdit && publishForm.headhuntingFirmCommissionValue != 0)"
            >
                <el-input
                    class="input-commission-value input-commission-rate"
                    v-model.number="publishForm.headhuntingFirmCommissionValue"
                    :disabled="isEdit"
                >
                    <span slot="prefix">猎企</span>
                    <span slot="suffix">%</span>
                </el-input>
            </el-form-item>
            <el-form-item
                prop="otherFirmCommissionValue"
                class="firm-commission-value other-firm"
                v-if="!isEdit || (isEdit && publishForm.otherFirmCommissionValue != 0)"
            >
                <el-input
                    class="input-commission-value input-commission-rate"
                    v-model.number="publishForm.otherFirmCommissionValue"
                    :disabled="isEdit"
                >
                    <span slot="prefix">非猎企</span>
                    <span slot="suffix">%</span>
                </el-input>
            </el-form-item>
            <el-form-item label="保证期：">
                <el-select
                    v-model.number="publishForm.publicGuaranteePeriod"
                    placeholder="请选择保证期"
                    :disabled="isEdit"
                >
                    <el-option label="无" :value="0"></el-option>
                    <el-option label="3个月" :value="3"></el-option>
                    <el-option label="6个月" :value="6"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="退款约定：" prop="refundPolicyDescription">
                <el-input
                    type="textarea"
                    :rows="4"
                    v-model="publishForm.refundPolicyDescription"
                    :disabled="isEdit"
                ></el-input>
            </el-form-item>
            <el-form-item label="设置可见猎企：" prop="visibleFirmIdList">
                <el-select
                    multiple
                    class="input-visible-firm"
                    placeholder="请选择可见猎企"
                    v-model="publishForm.visibleFirmIdList"
                >
                    <el-option
                        label="全部猎企(默认)"
                        :value="0"
                        @click.native.prevent="firmSelectedAll"
                    ></el-option>
                    <el-option
                        v-for="(item, index) in companyList"
                        :label="item.name"
                        :key="index"
                        :value="item.firmId"
                        class="company-item"
                        @click.native="firmSelected(index)"
                    ></el-option>
                    <el-option
                        label="加载更多"
                        class="get-more-btn"
                        @click.native.stop="getCompanyList"
                        v-if="hasMoreCompany"
                    ></el-option>
                    <span slot="empty">暂无数据</span>
                </el-select>
            </el-form-item>
            <el-form-item label="雇主匿名处理：" prop="anonymousFirmName">
                <el-input
                    v-model="publishForm.anonymousFirmName"
                    placeholder="如：国内某知名电商公司(选填)"
                ></el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="handleCloseDialog">取消</el-button>
            <el-button type="primary" @click="publishJob">确定</el-button>
        </span>
    </el-dialog>
</template>

<script>
import { job as jobUrl, company as companyUrl } from '#/js/config/api.json';
export default {
    props: {
        type: {
            type: [Number, String],
            default: 0
        }
    },
    data() {
        return {
            loading: false,
            jobData: {
                name: "",
                location: "",
                firmShortName: "",
                minSalar: 0,
                commissionType: 0,
                commissionValue: 0,
                guaranteePeriod: 0
            },
            dialogVisible: false,
            publishForm: {
                publicCommissionType: 0,
                publicCommissionValue: 10,
                headhuntingFirmCommissionValue: 0,
                otherFirmCommissionValue: 0,
                publicGuaranteePeriod: 6,
                refundPolicyDescription: "按照雇主退款策略执行",
                visibleFirmIdList:  [0],
                anonymousFirmName: ""
            },
            publishFormRules: {
                publicCommissionValue: [{
                    required: true, message: '请填写市场佣金', trigger: 'blur'
                }, {
                    type: 'number', message: '市场佣金必须输入数字格式', trigger: 'blur'
                }, {
                    validator: this.validateCommissionValue, trigger: 'blur'
                }],
                headhuntingFirmCommissionValue: [{
                    required: true, message: '请填写猎企类型公司分佣比例', trigger: 'blur'
                }, {
                    type: 'number', message: '分佣比例必须输入数字格式', trigger: 'blur'
                }, {
                    validator: this.validateHFCommissionValue, trigger: 'blur'
                }],
                otherFirmCommissionValue: [{
                    required: true, message: '请填写非猎企类型公司分佣比例', trigger: 'blur'
                }, {
                    type: 'number', message: '分佣比例必须输入数字格式', trigger: 'blur'
                }, {
                    validator: this.validateOFCommissionValue, trigger: 'blur'
                }],
                publicGuaranteePeriod: [{
                    required: true, message: '请填写保证期', trigger: 'blur'
                }, {
                    type: 'number', message: '保证期必须输入数字格式', trigger: 'blur'
                }, {
                    min: 0, max: 6, message: '保证期的范围在0-6个月之间', trigger: 'blur'
                }],
                refundPolicyDescription: [{
                    required: true, message: '请填写退款约定', trigger: 'blur'
                }, {
                    max: 500, message: '退款约定最长支持500个字符', trigger: 'blur'
                }]
            },
            companyList: [],
            fetchCompanyStart: 0,
            hasMoreCompany: false,
            isEdit: false,
            jobList: [],
            headhuntingFirmCommissionValue: 0,
            otherFirmCommissionValue: 0
        }
    },
    computed: {
        jobName() {
            return this.jobData.jobName ? this.jobData.jobName : this.jobData.name
        },
        isBatch() {
            return this.type == 1;
        }
    },
    methods: {
        show(data, isEdit) {
            this.getCompanyList();
            this.getFirmCommissionValue();
            if(this.isBatch) {
                this.jobList = data;
                this.publishForm.publicCommissionType = "";
                this.publishForm.publicCommissionValue = "";
            } else {
                this.jobData = data;
                if(isEdit) {
                    this.isEdit = isEdit;
                    this.getPublicToMarketJson().then((res) => {
                        if(res.publicCommissionType == 1) {
                            res.publicCommissionValue /= 1000;
                        }
                        this.publishForm = Object.assign(this.publishForm, res);
                        if(res.visibleFirmIdList[0] === '00000000-0000-0000-0000-000000000000') {
                            this.publishForm.visibleFirmIdList = [0];
                        }
                    });
                } else {
                    this.publishForm.publicCommissionType = data.commissionType;
                    if(data.commissionType == 0) {
                        let valueIndex = data.commissionValue.lastIndexOf("(");
                        this.publishForm.publicCommissionValue = parseInt(data.commissionValue.slice(valueIndex + 1));
                    } else {
                        this.publishForm.publicCommissionValue = parseInt(data.commissionValue);
                    }
                }
            }
            this.dialogVisible = true;
        },
        getCompanyList() {
            _request({
                url: companyUrl.white_list_firms,
                method: "GET",
                data: {
                    start: this.fetchCompanyStart,
                    take: 20
                }
            }).then((res) => {
                if(res.length == 20) {
                    this.hasMoreCompany = true;
                    this.fetchCompanyStart += 20;
                } else {
                    this.hasMoreCompany = false;
                }
                if(res.length > 0) {
                    res.forEach((item) => {
                        item.selected = false;
                    })
                }
                this.companyList = this.companyList.concat(res);
            })
        },
        getPublicToMarketJson() {
           return  _request({
                url: jobUrl.publicize_info.replace(/\%p/ig, this.jobData.id),
                method: "GET",
                data: {
                    _: new Date().getTime()
                }
            })
        },
        validateCommissionValue(rule, commissionValue, callback) {
            if(this.publishForm.publicCommissionType === '') {
                callback(new Error("请填写佣金类型"));
            }
            if(!this.isBatch) {
                let minProportion = 10,
                    minFixed = 15,
                    minSalar = this.jobData.minSalar,
                    originCommissionType = this.jobData.commissionType,
                    originCommissionValue = this.jobData.commissionValue,
                    publishCommissionType = this.publishForm.publicCommissionType,
                    errorMsg = "";
                if(originCommissionType == 1 && publishCommissionType == 0) {
                    errorMsg = '内部佣金为固定佣金时，市场佣金也需要是固定佣金';
                } else if(publishCommissionType == originCommissionType) {
                    errorMsg = (originCommissionType === 0 && (commissionValue > originCommissionValue)) || (originCommissionType == 1 && (commissionValue * 1000 > originCommissionValue)) ? '您的市场佣金大于签约佣金，请调整' : ''
                } else {
                    if(publishCommissionType == 0) {
                        if(commissionValue < minProportion) {
                            errorMsg = '市场佣金比例不能低于' + minProportion + '%';
                        } else if(minSalar * commissionValue * 12 < minFixed * 100) {
                            errorMsg = '最低市场佣金不能低于' + minFixed + 'K，请调整';
                        }
                    } else {
                        if(commissionValue < minFixed) {
                            errorMsg = '固定市场佣金不能少于' + minFixed + 'k(千)';
                        } else if(commissionValue > minSalar * 12 * originCommissionValue / 100) {
                            errorMsg = '您的市场佣金大于签约佣金，请调整';
                        }
                    }
                }
                if(errorMsg !== "") {
                    callback(new Error(errorMsg));
                } else {
                    callback();
                } 
            } else {
                callback();
            }
        },
        firmSelectedAll() {
            this.publishForm.visibleFirmIdList = [0];
            this.companyList.forEach((item) => {
                item.selected = false;
            });
        },
        firmSelected(index) {
            let visibleFirmIdList = this.publishForm.visibleFirmIdList;
            if(visibleFirmIdList[0] == 0) {
                this.publishForm.visibleFirmIdList.shift();
            }
            if(visibleFirmIdList[0] !== 0 && visibleFirmIdList.length == this.companyList.length) {
                this.publishForm.visibleFirmIdList = [0];
            }
            let selected = this.companyList[index].selected;
            this.companyList[index].selected = !selected;
        },
        publishJob() {
            this.$refs.publishForm.validate((valid) => {
                if(valid) {
                    this.loading = true;
                    let publishForm = this.publishForm,
                        visibleFirmIdArr = [];
                    if(publishForm.visibleFirmIdList[0] == 0) {
                        visibleFirmIdArr = ["00000000-0000-0000-0000-000000000000"];
                    } else {
                        visibleFirmIdArr = publishForm.visibleFirmIdList;
                    }
                    let param = {
                            PublicCommissionType: publishForm.publicCommissionType,
                            PublicCommissionValue: publishForm.publicCommissionValue,
                            HeadhuntingFirmCommissionValue: publishForm.headhuntingFirmCommissionValue,
                            OtherFirmCommissionValue: publishForm.otherFirmCommissionValue,
                            PublicGuaranteePeriod: publishForm.publicGuaranteePeriod,
                            RefundPolicyDescription: publishForm.refundPolicyDescription,
                            visibleFirmIds: visibleFirmIdArr.join(","),
                            AnonymousFirmName: publishForm.anonymousFirmName,
                            __RequestVerificationToken: this.$store.state.verificationToken.verificationToken
                        },
                        url = "";
                    if(this.isBatch) {
                        let jobIds = [],
                            batchParams = {};
                        this.jobList.forEach(item => {
                            jobIds.push(item.jobId);
                        });
                        batchParams.jobIds = jobIds;
                        batchParams.model = param;
                        url = jobUrl.job_publish_batch;
                        _request({
                            url: url,
                            method: "POST",
                            header: {
                                "Content-Type": "application/json"
                            },
                            data: batchParams,
                            throwBusinessError: true
                        }).then((res) => {
                            this.loading = false;
                            shortTips("发布成功！");
                            this.$emit("update-list");
                            this.handleCloseDialog();
                        }).catch((err) => {
                            this.loading = false;
                            if(err.data && err.data.length > 0) {
                                let jobs = err.data.join(', ');
                                shortTips(`职位“${jobs}”${err.message}`);
                            } else {
                                shortTips(err.message);
                            }
                        });
                    } else {
                        if(this.isEdit) {
                            url = jobUrl.job_publish_edit_url;
                            delete param.HeadhuntingFirmCommissionValue;
                            delete param.OtherFirmCommissionValue;
                        } else {
                            url = jobUrl.job_publish;
                        }
                        _request({
                            url: url.replace(/\%p/ig, this.jobData.id),
                            method: "POST",
                            header: {
                                "Content-Type": "application/x-www-form-urlencoded"
                            },
                            data: param,
                        }).then((res) => {
                            this.loading = false;
                            this.$emit("refresh"); 
                            this.handleCloseDialog();
                        }).catch((err) => {
                            this.loading = false;
                        });
                    }
                } else {
                    console.log("error submit");
                }
            });
        },
        handleCloseDialog() {
            this.dialogVisible = false;
            this.$refs.publishForm.resetFields();
            this.companyList = [];
        },
        getFirmCommissionValue() {
            _request({
                url: companyUrl.commission_standard,
                method: "GET"
            }).then((res) => {
                if(!this.isEdit) {
                    this.headhuntingFirmCommissionValue = this.publishForm.headhuntingFirmCommissionValue = res.headhuntingFirmCommissionValue;
                    this.otherFirmCommissionValue = this.publishForm.otherFirmCommissionValue = res.otherFirmCommissionValue;
                }
            }).catch(err => {
                console.log(err);
            });
        },
        validateHFCommissionValue(rule, value, callback) {
            if(!this.isEdit) {if(value > 100 || value < 0) {
                    callback(new Error("比例值的范围是0~100！"));
                } else if(value > this.headhuntingFirmCommissionValue + 30 || value < this.headhuntingFirmCommissionValue - 30) {
                    callback(new Error("分佣比例不能超过基准佣金上下30%！"))
                } else {
                    callback();
                }
            } else {
                callback();
            }
        },
        validateOFCommissionValue(rule, value, callback) {
            if(!this.isEdit) {
                if(value > 100 || value < 0) {
                    callback(new Error("比例值的范围是0~100！"));
                } else if(value > this.otherFirmCommissionValue + 30 || value < this.otherFirmCommissionValue - 30) {
                    callback(new Error("分佣比例不能超过基准佣金上下30%！"))
                } else {
                    callback();
                }
            } else {
                callback();
            }
        }
    }
}
</script>

<style lang="scss" scope>
    .publish-info {
        display: flex;
        justify-content: space-between;
        color: #666;
        font-size: 16px;
        line-height: 46px;
        padding-left: 20px;
        margin-bottom: 8px;
        .info-left {
            flex-grow: 5;
            max-width: 270px;
            margin-right: 15px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .info-middle {
            flex-grow: 3;
        }
        .info-right {
            flex-grow: 2;
        }
        .info-title {
            color: #333;
        }
        .info-location {
            color: #b9b9b9;
        }
        .info-warning {
            color: #ff7200;
        }
    }
    .el-form {
        margin-right: 15px;
    }
    .firm-commission-value.el-form-item {
        display: inline-block;
        &.other-firm {
            .el-form-item__content {
                margin-left: 20px !important;
            }
        }
        .el-form-item__error {
            white-space: nowrap;
        }
    }
    .input-commission-type.el-select {
        .el-input {
            width: 200px;
            margin-right: 20px;
        }
    }
    .input-commission-value.el-input {
        width: 200px;
    }
    .input-commission-rate.el-input {
        .el-input__inner {
            text-align: right;
            &:nth-of-type(1) {
                padding-left: 53px;
            }
            &:nth-of-type(2) {
                padding-left: 67px;
            }
        }
        .el-input__prefix {
            color: #999;
            padding: 0 10px;
        }
        &.is-disabled {
            .el-input__prefix {
                color: #C0C4CC;
            }
        }
    }
    .input-visible-firm.el-select {
        width: 100%;
        .el-tag.el-tag--info {
            font-size: 14px;
            height: 18px;
            line-height: 18px;
            background-color: #fff;
            border: none;
            color: #666;
            padding: 0;
            &:nth-of-type(1) {
                padding-left: 8px;
            }
            &:nth-of-type(n+2) {
                &::before {
                    content: ",";
                    margin-right: 6px;
                }
            }
            .el-tag__close {
                display: none;
            }
        }
    }
    .batch-publish-job-info {
        margin-bottom: 24px;
        line-height: 22px;
        .batch-public-job-name {
            color: $primary;

        }
    }
</style>
<style lang="scss">
    .commission-type-dropdown.el-select-dropdown.el-popper {
        min-width: 200px !important;
    }
</style>