var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "transfer-job-dialog",
      attrs: {
        title: "移交职位",
        visible: _vm.dialogVisible,
        width: "698px",
        "show-close": false,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "transfer-content-wrap" },
        [
          _c("team-member-select", {
            ref: "teamSelect",
            attrs: {
              "append-to-body": true,
              isSingleSelect: true,
              filterLoginUser: true,
              singleSelectTip: "移交职位仅支持单选",
              isAllMembers: true,
              isShowDimission: false,
              visible: _vm.teamMemberPopoverVisible,
              selected: _vm.userId ? [_vm.userId] : [],
            },
            on: {
              "item-select-cb": _vm.membersSelect,
              cancel: _vm.handleClose,
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }