<template>
    <el-dialog
        class="transfer-job-dialog"
        title="移交职位"
        :visible.sync="dialogVisible"
        width="698px"
        :show-close="false"
        v-loading="loading"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
    >
        <div class="transfer-content-wrap">
            <team-member-select
                ref="teamSelect"
                :append-to-body="true"
                :isSingleSelect="true"
                :filterLoginUser="true"
                singleSelectTip="移交职位仅支持单选"
                :isAllMembers="true"
                :isShowDimission="false"
                :visible="teamMemberPopoverVisible"
                :selected="userId ? [userId] : []"
                @item-select-cb="membersSelect"
                @cancel="handleClose"
            ></team-member-select>
        </div>
    </el-dialog>
</template>
<script>
import TeamMemberSelect from '#/component/common/team-member-select.vue';
import myOrdersService from "#/js/service/myOrdersService.js";
export default {
    name: "transferJobDialog",
    components: {
        TeamMemberSelect
    },
    props: {
        // 是否是管理员进行移交职位（的记录）
        isAdminTransfer: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            dialogVisible: false,
            teamMemberPopoverVisible: false,
            userId: '',
            loading: false,
            checkedArr: [], //被选择的数据
            removeItemIds:[]
        };
    },
    mounted() {
        this.removeItemIds = [this.$store.state.user.userInfo.id];
    },
    watch: {
        '$store.state.user.userInfo.id':function(val){
            this.removeItemIds = [val];
        }
    },
    methods: {
        initDialog(data){
            this.checkedArr = data.checkedArr ? data.checkedArr : data;
            this.dialogVisible = true;
            this.teamMemberPopoverVisible = true;
        },
        handleClose() {
            this.dialogVisible = false;
            this.teamMemberPopoverVisible = false;
        },
        membersSelect(value, text) {
            this.userId = value[0] || '';
            this.handleConfirm();
        },
        handleConfirm(){
            if(!this.userId){
                shortTips('请选择成员');
                return;
            }
            this.loading = true;
            let jobIds = [];
            this.checkedArr.map(item=>{
                let id = item.jobId ? item.jobId : item.id;
                jobIds.push(id);
            })
            if(this.isAdminTransfer) {
                // 管理员进行移交属于移交抢单记录 故需要调用不同的接口
                _request({
                    url: "/Headhunting/Job/%p/Transfer".replace(/\%p/ig, jobIds[0]),
                    method: "POST",
                    data: {
                        newOwnerId: this.userId,
                        __RequestVerificationToken: this.$store.state.verificationToken.verificationToken
                    }
                }).then(res => {
                    this.loading = false;
                    this.dialogVisible = false;
                    this.teamMemberPopoverVisible = false;
                    shortTips("职位移交成功");
                    this.$emit('update-list');
                })
                .catch(err => {
                    console.log(err);
                    this.loading = false;
                });
            } else {
                myOrdersService.transferJob(jobIds,this.userId)
                .then(res => {
                    this.loading = false;
                    this.dialogVisible = false;
                    this.teamMemberPopoverVisible = false;
                    shortTips("职位移交成功");
                    this.$emit('update-list');
                })
                .catch(err => {
                    console.log(err);
                    this.loading = false;
                });
            }

        }
    }
};
</script>
<style lang="scss" scoped>
.transfer-job-dialog {
    /deep/ .el-dialog__body {
        padding: 0;
    }
}
.transfer-content-wrap{
    height: 288px;
}
</style>
