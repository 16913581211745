var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      attrs: {
        width: "700px",
        title: "修改职位状态",
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.handleCloseDialog,
      },
    },
    [
      !_vm.isBatch
        ? _c("div", { staticClass: "publish-info" }, [
            _c(
              "div",
              {
                staticClass: "info-left",
                attrs: {
                  title: `${_vm.jobName}[${_vm.jobData.location}]-${_vm.jobData.firmShortName}`,
                },
              },
              [
                _c("span", { staticClass: "info-title" }, [_vm._v("职位：")]),
                _c("span", [_vm._v(_vm._s(_vm.jobName))]),
                _c("span", { staticClass: "info-location" }, [
                  _vm._v("[" + _vm._s(_vm.jobData.location) + "]"),
                ]),
                _vm._v("\n            -\n            "),
                _c("span", [_vm._v(_vm._s(_vm.jobData.firmShortName))]),
              ]
            ),
            _c("div", { staticClass: "info-middle" }, [
              _c("span", { staticClass: "info-title" }, [_vm._v("佣金：")]),
              _c("span", { staticClass: "info-warning" }, [
                _vm._v(_vm._s(_vm.jobData.commissionValue)),
              ]),
            ]),
            _c("div", { staticClass: "info-right" }, [
              _c("span", { staticClass: "info-title" }, [_vm._v("保证期：")]),
              _c("span", { staticClass: "info-warning" }, [
                _vm._v(_vm._s(_vm.jobData.guaranteePeriod)),
              ]),
              _vm._v("\n            个月\n        "),
            ]),
          ])
        : _c(
            "div",
            { staticClass: "batch-publish-job-info" },
            [
              _vm._v("\n        你正在将\n        "),
              _vm._l(_vm.jobList, function (job, index) {
                return _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: index < 5,
                        expression: "index < 5",
                      },
                    ],
                    key: index,
                    staticClass: "batch-public-job-name",
                  },
                  [
                    _vm._v("\n            " + _vm._s(job.jobName)),
                    _vm.jobList.length > 1 &&
                    ((_vm.jobList.length <= 5 &&
                      index < _vm.jobList.length - 1) ||
                      (_vm.jobList.length > 5 && index < 4))
                      ? _c("span", [_vm._v("、")])
                      : _vm._e(),
                  ]
                )
              }),
              _vm.jobList.length > 1
                ? _c("span", { staticClass: "batch-public-job-length" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.jobList.length > 5
                            ? `等 ${_vm.jobList.length} 个职位`
                            : `${_vm.jobList.length}个职位`
                        ) +
                        "\n        "
                    ),
                  ])
                : _vm._e(),
              _vm._v("\n        发布到市场\n    "),
            ],
            2
          ),
      _c(
        "el-form",
        {
          ref: "publishForm",
          attrs: {
            "label-width": "130px",
            model: _vm.publishForm,
            rules: _vm.publishFormRules,
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "市场佣金：", prop: "publicCommissionValue" } },
            [
              _c(
                "el-select",
                {
                  staticClass: "input-commission-type",
                  attrs: {
                    "popper-class": "commission-type-dropdown",
                    placeholder: "佣金类型",
                    disabled: _vm.isEdit,
                  },
                  model: {
                    value: _vm.publishForm.publicCommissionType,
                    callback: function ($$v) {
                      _vm.$set(_vm.publishForm, "publicCommissionType", $$v)
                    },
                    expression: "publishForm.publicCommissionType",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "按比例", value: 0 } }),
                  _c("el-option", { attrs: { label: "固定佣金", value: 1 } }),
                ],
                1
              ),
              _c(
                "el-input",
                {
                  staticClass: "input-commission-value",
                  attrs: { disabled: _vm.isEdit },
                  model: {
                    value: _vm.publishForm.publicCommissionValue,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.publishForm,
                        "publicCommissionValue",
                        _vm._n($$v)
                      )
                    },
                    expression: "publishForm.publicCommissionValue",
                  },
                },
                [
                  _c("span", { attrs: { slot: "suffix" }, slot: "suffix" }, [
                    _vm._v(
                      _vm._s(
                        _vm.publishForm.publicCommissionType == 0
                          ? "%"
                          : "K(千)"
                      )
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
          !_vm.isEdit ||
          (_vm.isEdit && _vm.publishForm.headhuntingFirmCommissionValue != 0)
            ? _c(
                "el-form-item",
                {
                  staticClass: "firm-commission-value",
                  attrs: {
                    label: "分佣比例：",
                    prop: "headhuntingFirmCommissionValue",
                  },
                },
                [
                  _c(
                    "el-input",
                    {
                      staticClass:
                        "input-commission-value input-commission-rate",
                      attrs: { disabled: _vm.isEdit },
                      model: {
                        value: _vm.publishForm.headhuntingFirmCommissionValue,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.publishForm,
                            "headhuntingFirmCommissionValue",
                            _vm._n($$v)
                          )
                        },
                        expression:
                          "publishForm.headhuntingFirmCommissionValue",
                      },
                    },
                    [
                      _c(
                        "span",
                        { attrs: { slot: "prefix" }, slot: "prefix" },
                        [_vm._v("猎企")]
                      ),
                      _c(
                        "span",
                        { attrs: { slot: "suffix" }, slot: "suffix" },
                        [_vm._v("%")]
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          !_vm.isEdit ||
          (_vm.isEdit && _vm.publishForm.otherFirmCommissionValue != 0)
            ? _c(
                "el-form-item",
                {
                  staticClass: "firm-commission-value other-firm",
                  attrs: { prop: "otherFirmCommissionValue" },
                },
                [
                  _c(
                    "el-input",
                    {
                      staticClass:
                        "input-commission-value input-commission-rate",
                      attrs: { disabled: _vm.isEdit },
                      model: {
                        value: _vm.publishForm.otherFirmCommissionValue,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.publishForm,
                            "otherFirmCommissionValue",
                            _vm._n($$v)
                          )
                        },
                        expression: "publishForm.otherFirmCommissionValue",
                      },
                    },
                    [
                      _c(
                        "span",
                        { attrs: { slot: "prefix" }, slot: "prefix" },
                        [_vm._v("非猎企")]
                      ),
                      _c(
                        "span",
                        { attrs: { slot: "suffix" }, slot: "suffix" },
                        [_vm._v("%")]
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "保证期：" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择保证期", disabled: _vm.isEdit },
                  model: {
                    value: _vm.publishForm.publicGuaranteePeriod,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.publishForm,
                        "publicGuaranteePeriod",
                        _vm._n($$v)
                      )
                    },
                    expression: "publishForm.publicGuaranteePeriod",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "无", value: 0 } }),
                  _c("el-option", { attrs: { label: "3个月", value: 3 } }),
                  _c("el-option", { attrs: { label: "6个月", value: 6 } }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "退款约定：", prop: "refundPolicyDescription" } },
            [
              _c("el-input", {
                attrs: { type: "textarea", rows: 4, disabled: _vm.isEdit },
                model: {
                  value: _vm.publishForm.refundPolicyDescription,
                  callback: function ($$v) {
                    _vm.$set(_vm.publishForm, "refundPolicyDescription", $$v)
                  },
                  expression: "publishForm.refundPolicyDescription",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "设置可见猎企：", prop: "visibleFirmIdList" } },
            [
              _c(
                "el-select",
                {
                  staticClass: "input-visible-firm",
                  attrs: { multiple: "", placeholder: "请选择可见猎企" },
                  model: {
                    value: _vm.publishForm.visibleFirmIdList,
                    callback: function ($$v) {
                      _vm.$set(_vm.publishForm, "visibleFirmIdList", $$v)
                    },
                    expression: "publishForm.visibleFirmIdList",
                  },
                },
                [
                  _c("el-option", {
                    attrs: { label: "全部猎企(默认)", value: 0 },
                    nativeOn: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.firmSelectedAll.apply(null, arguments)
                      },
                    },
                  }),
                  _vm._l(_vm.companyList, function (item, index) {
                    return _c("el-option", {
                      key: index,
                      staticClass: "company-item",
                      attrs: { label: item.name, value: item.firmId },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.firmSelected(index)
                        },
                      },
                    })
                  }),
                  _vm.hasMoreCompany
                    ? _c("el-option", {
                        staticClass: "get-more-btn",
                        attrs: { label: "加载更多" },
                        nativeOn: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.getCompanyList.apply(null, arguments)
                          },
                        },
                      })
                    : _vm._e(),
                  _c("span", { attrs: { slot: "empty" }, slot: "empty" }, [
                    _vm._v("暂无数据"),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "雇主匿名处理：", prop: "anonymousFirmName" } },
            [
              _c("el-input", {
                attrs: { placeholder: "如：国内某知名电商公司(选填)" },
                model: {
                  value: _vm.publishForm.anonymousFirmName,
                  callback: function ($$v) {
                    _vm.$set(_vm.publishForm, "anonymousFirmName", $$v)
                  },
                  expression: "publishForm.anonymousFirmName",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.handleCloseDialog } }, [
            _vm._v("取消"),
          ]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.publishJob } },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }