import { media as mediaUrl } from '#/js/config/javaApi.json';

export default {
    getUserSig() {
        return _request({
            method: 'POST',
            url: mediaUrl.get_sig,
            baseURL: 'LbdJavaApi',
            javaProject: 'oldJavaApi',
            headers: {
                "AuthorizationMedia": _getCookie('access_token')
            }
        })
    },
    getRecordId(params) {
        return _request({
            method: 'POST',
            url: mediaUrl.get_record_id,
            baseURL: 'LbdJavaApi',
            javaProject: 'oldJavaApi',
            headers: {
                "AuthorizationMedia": _getCookie('access_token')
            },
            data: params
        })
    },
    getPlaySign(params) {
        return _request({
            method: 'POST',
            url: mediaUrl.get_play_sign,
            baseURL: 'LbdJavaApi',
            javaProject: 'oldJavaApi',
            headers: {
                "AuthorizationMedia": _getCookie('access_token')
            },
            data: params
        })
    },
    getJobMadiaList(params) {
        return _request({
            method: 'POST',
            url: mediaUrl.get_media_list,
            baseURL: 'LbdJavaApi',
            javaProject: 'oldJavaApi',
            headers: {
                "AuthorizationMedia": _getCookie('access_token')
            },
            data: params
        })
    },
    getJobMappingFileId(params = { mappingIds = [] } = {}) {
        return _request({
            method: 'POST',
            url: mediaUrl.get_mapping_fileid,
            baseURL: 'LbdJavaApi',
            javaProject: 'oldJavaApi',
            headers: {
                "AuthorizationMedia": _getCookie('access_token')
            },
            data: params
        })
    },
    deleteJobMappingMedia(params = { fileId = "", mappingId = "" } = {}) {
        return _request({
            method: 'DELETE',
            url: mediaUrl.delete_mapping_media,
            baseURL: 'LbdJavaApi',
            javaProject: 'oldJavaApi',
            headers: {
                "AuthorizationMedia": _getCookie('access_token')
            },
            data: params
        })
    },
    checkHasViewMedia(params = { jobId = "" } = {}) {
        return _request({
            method: 'POST',
            url: mediaUrl.has_view_new_media,
            baseURL: 'LbdJavaApi',
            javaProject: 'oldJavaApi',
            headers: {
                "AuthorizationMedia": _getCookie('access_token')
            },
            data: params
        })
    },
    checkRecordingAuthority(params = { jobId = "" } = {}) {
        return _request({
            method: 'POST',
            url: mediaUrl.check_recording_authority,
            baseURL: 'LbdJavaApi',
            javaProject: 'oldJavaApi',
            headers: {
                "AuthorizationMedia": _getCookie('access_token')
            },
            data: params
        })
    },
    rotateVideo(params) {
        return _request({
            method: 'POST',
            url: mediaUrl.rotate_media,
            baseURL: 'LbdJavaApi',
            javaProject: 'oldJavaApi',
            headers: {
                "AuthorizationMedia": _getCookie('access_token')
            },
            data: params
        })
    }
}
