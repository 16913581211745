var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      attrs: {
        width: "400px",
        title: "分享我的职位名片",
        "custom-class": "share-job-share-dialog",
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.handleCloseDialog,
      },
    },
    [
      _c("div", { staticClass: "share-info-edit-container" }, [
        _c("div", { staticClass: "share-info-edit" }, [
          _c(
            "div",
            { staticClass: "share-info-top" },
            [
              !_vm.isEditShareTitle
                ? _c("span", { staticClass: "share-info-title" }, [
                    _vm._v(_vm._s(_vm.userInfo.linkHeadline)),
                  ])
                : _c("el-input", {
                    attrs: { type: "textarea", maxlength: 50, rows: "2" },
                    model: {
                      value: _vm.linkHeadline,
                      callback: function ($$v) {
                        _vm.linkHeadline = $$v
                      },
                      expression: "linkHeadline",
                    },
                  }),
            ],
            1
          ),
          _c("div", { staticClass: "share-info-bottom" }, [
            _c("span", { staticClass: "share-info-text" }, [
              _vm._v(
                "\n                    " +
                  _vm._s(_vm.userInfo.realName) +
                  "：" +
                  _vm._s(_vm.userInfo.signature) +
                  "\n                "
              ),
            ]),
            _c("img", {
              attrs: {
                src: "https://hstatic.hirede.com/lbd/images/job-share-logo.jpg",
              },
            }),
          ]),
        ]),
        !_vm.isEditShareTitle
          ? _c(
              "span",
              {
                staticClass: "text-operate-btn share-info-edit-btn",
                on: {
                  click: function ($event) {
                    _vm.isEditShareTitle = true
                  },
                },
              },
              [_vm._v("编辑")]
            )
          : _vm._e(),
        !_vm.isEditShareTitle
          ? _c("div", { staticClass: "share-info-edit-tip" }, [
              _vm._v("\n            点击编辑即可自定义分享文案！\n        "),
            ])
          : _vm._e(),
        _vm.isEditShareTitle
          ? _c(
              "span",
              {
                staticClass: "text-operate-btn share-info-confirm-btn",
                on: { click: _vm.updateJobShareCardInfo },
              },
              [_vm._v("保存")]
            )
          : _vm._e(),
      ]),
      _c("div", { staticClass: "share-qcode-container" }, [
        _c("img", {
          staticClass: "share-qcode-image",
          attrs: { src: _vm.shareQcodeSrc },
        }),
        _c("div", { staticClass: "share-qcode-tip" }, [
          _vm._v(
            "\n            打开微信，扫一扫～快速分享我的职位名片\n            "
          ),
          _c("br"),
          _c("br"),
          _vm._v("\n            可到【"),
          _c(
            "span",
            {
              staticClass: "text-operate-btn",
              on: { click: _vm.goToMySharedJob },
            },
            [_vm._v("我分享的职位")]
          ),
          _vm._v(
            "】查看修改我分享的职位，分享中职位：" +
              _vm._s(_vm.userInfo.jobSharingCount) +
              "个\n        "
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }